import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import Button from '#components/ui/Button';
// @ts-ignore
import IntlTelInputWithUtils from 'intl-tel-input/react/build/IntlTelInputWithUtils.js';
import styles from './Locator.module.scss';
import { getIpLookup } from '#api/geoIpLookup';
import analytics from '#utils/analytics';
import { getPhoneData } from '#api/getPhoneData';
import { initAnimation } from '#store/initAnimation';
import { locatedNumber } from '#store/locatedNumber';
import { phoneData } from '#store/phoneData';
import { useStore } from '@nanostores/react';
import { loadingButton } from '#store/locate';
import { saveCheckoutExtraData } from '#utils/saveCheckoutExtraData';
import { clearFeaturesStorage } from '#utils/clearFeaturesStorage';

type Props = {
  buttonText: string;
  errorText: string;
  icon?: string;
  customStyle?: string;
};

const Locator = ({ buttonText, errorText, icon, customStyle }: Props) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [number, setNumber] = useState<string | null>(null);
  const [notice, setNotice] = useState<string | null>(null);
  const $loadingButton = useStore(loadingButton);
  const telInputRef = useRef<any>(null);

  useEffect(() => {
    if (!isValid && number) {
      setNotice(errorText);
    } else {
      setNotice(null);
    }
  }, [number]);

  useEffect(() => {
    telInputRef.current.getInput().addEventListener('keyup', (e: KeyboardEvent) => {
      e.preventDefault();
      if (e.code === '13') {
        handleSubmit(e);
      }
    });
  }, []);

  useEffect(() => {
    const phoneParam: string | null = new URLSearchParams(window.location.search).get(
      'phone_number',
    );
    if (phoneParam && /^[1-9][0-9]{4,14}$/.test(phoneParam)) {
      clearFeaturesStorage();
      savePhoneToStorage(phoneParam);
    }
  }, []);

  const savePhoneToStorage = (phoneParam: string) => {
    const { pathname } = window.location;
    if (pathname.includes('reverse-number-lookup')) {
      sessionStorage.setItem('isLookup', 'true');
      sessionStorage.setItem('phoneNumberLookup', phoneParam);
    } else if (pathname.includes('find-lost-phone')) {
      sessionStorage.setItem('lostPhone', 'true');
      sessionStorage.setItem('phoneNumberLP', phoneParam);
    } else {
      sessionStorage.setItem('isMainPage', 'true');
      sessionStorage.setItem('phoneNumber', phoneParam);
    }
  };

  const handleSubmit = async (e: SyntheticEvent | KeyboardEvent): Promise<void> => {
    e.preventDefault();
    if (telInputRef.current) {
      telInputRef.current.getInput().blur();
    }
    if (isValid && number) {
      clearFeaturesStorage();
      savePhoneToStorage(number);
      saveCheckoutExtraData(number);
      loadingButton.set(true);
      analytics.event({ action: 'Locate number' });
      locatedNumber.set(number);
      const { data } = await getPhoneData(number);
      if (data) {
        phoneData.set(data);
        initAnimation.set(true);
      }
    } else {
      setNotice(errorText);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.item}>
        <IntlTelInputWithUtils
          onChangeNumber={setNumber}
          onPhoneNumberBlur={handleSubmit}
          onChangeValidity={setIsValid}
          ref={telInputRef}
          initOptions={{
            initialCountry: 'auto',
            separateDialCode: true,
            autoInsertDialCode: true,
            nationalMode: false,
            strictMode: true,
            geoIpLookup: (callback: (iso: string) => void) => getIpLookup(callback),
          }}
        />
        {notice && (
          <div className={`${styles.notice} ${customStyle && styles[customStyle]}`}>{notice}</div>
        )}
      </div>
      <Button
        text={buttonText}
        icon={icon}
        styleType="orange"
        isLoading={$loadingButton}
        onClick={handleSubmit}
        customStyle="locate"
      />
    </form>
  );
};
export default Locator;
